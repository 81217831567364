import moment from "moment";
import { iDBHoraires } from "../../services/IDBHoraires";
import isDefined from "../../tools/isDefined";

export default class DataService {
    saveWakeUpTime(date, datetime) {
        const dateNormalized = date.format('YYYY-MM-DD');
        const datetimeNormalized = isDefined(datetime) ? datetime.format("YYYY-MM-DDTHH:mm") : undefined;

        return iDBHoraires.get(dateNormalized)
            .then((currentRecord = {}) => {
                const newRecord = {
                    ...currentRecord,
                    date: dateNormalized,
                    wakeUpDatetime: datetimeNormalized
                };

                return iDBHoraires.set(newRecord);
            });
    }

    saveSleepTime(date, datetime) {
        const dateNormalized = date.format('YYYY-MM-DD');
        const datetimeNormalized = isDefined(datetime) ? datetime.format("YYYY-MM-DDTHH:mm") : undefined;

        return iDBHoraires.get(dateNormalized)
            .then((currentRecord = {}) => {
                const newRecord = {
                    ...currentRecord,
                    date: dateNormalized,
                    sleepDatetime: datetimeNormalized
                };

                return iDBHoraires.set(newRecord);
            });
    }

    getWakeUpTime(date) {
        const dateNormalized = date.format('YYYY-MM-DD');
        return iDBHoraires.get(dateNormalized)
            .then((currentRecord) => {
                return isDefined(currentRecord?.wakeUpDatetime)
                    ? moment(currentRecord.wakeUpDatetime, "YYYY-MM-DDTHH:mm")
                    : undefined
            });
    }

    getSleepTime(date) {
        const dateNormalized = date.format('YYYY-MM-DD');
        return iDBHoraires.get(dateNormalized)
            .then((currentRecord) => {
                return isDefined(currentRecord?.sleepDatetime)
                    ? moment(currentRecord.sleepDatetime, "YYYY-MM-DDTHH:mm")
                    : undefined
            });
    }

    getAllTimes() {
        return iDBHoraires.getAll()
            .then((records) => {
                return isDefined(records)
                    ? records
                    : []
            });
    }
}

export const dataService = new DataService();