import React from "react";
import DownloadSave from "../download-save/DownloadSave";
import TimeInput from "../time-input/TimeInput";
import { dataService } from "./data.service";

class DailyInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        Promise.all([
            dataService.getWakeUpTime(this.props.date),
            dataService.getSleepTime(this.props.date)
        ])
            .then(([wakeUpTime, sleepTime]) => {
                this.setState({
                    wakeUpTime,
                    sleepTime,
                });
            });
    }

    componentDidUpdate(prevProps) {
        const dateHasChanged = prevProps.date !== this.props.date;
        if (dateHasChanged) {
            Promise.all([
                dataService.getWakeUpTime(this.props.date),
                dataService.getSleepTime(this.props.date)
            ])
                .then(([wakeUpTime, sleepTime]) => {
                    this.setState({
                        wakeUpTime,
                        sleepTime,
                    });
                });
        }
    }

    onChangeWakeUpTime(datetime) {
        dataService.saveWakeUpTime(this.props.date, datetime);
        this.setState({
            wakeUpTime: datetime,
        });
    }

    onChangeSleepTime(datetime) {
        dataService.saveSleepTime(this.props.date, datetime);
        this.setState({
            sleepTime: datetime,
        });
    }

    render() {
        const dateFormatted = this.props.date.format("L");

        return (
            <div>
                <div>
                    <span data-test="date-of-the-day">{dateFormatted}</span>
                    <DownloadSave
                        date={this.props.date}
                    ></DownloadSave>
                </div>
                <TimeInput
                    title="Heure de réveil"
                    datetime={this.state.wakeUpTime}
                    onChangeDateTime={(e) => { this.onChangeWakeUpTime(e) }}
                    testId="morning"
                ></TimeInput>
                <TimeInput
                    title="Heure de coucher"
                    datetime={this.state.sleepTime}
                    onChangeDateTime={(e) => { this.onChangeSleepTime(e) }}
                    testId="evening"
                ></TimeInput>
            </div>
        );
    }
}

export default DailyInput;