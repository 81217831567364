import moment from "moment";
import React from "react";
import DailyInput from "./daily-input/DailyInput";

class PageMain extends React.Component {

    constructor(props) {
        super(props);

        const dateNow = moment();

        // La saisie à effectuer est considérée pour la veille si c'est la première moitiée de la nuit.
        const initialDate = dateNow.hours() >= 4
            ? dateNow
            : moment(dateNow).subtract(1, "days");

        this.state = {
            date: initialDate
        };
    }

    render() {
        const goToPreviousDateElement = (
            <button onClick={
                () => {
                    this.setState({
                        date: moment(this.state.date).subtract(1, "days")
                    })
                }
            } data-test="previous-date-button">-1</button>
        );
        const goToNextDateElement = (
            <button onClick={
                () => {
                    this.setState({
                        date: moment(this.state.date).add(1, "days")
                    })
                }
            } data-test="next-date-button">+1</button>
        );

        return (
            <div className="PageMain">
                {goToPreviousDateElement}
                {goToNextDateElement}
                <DailyInput
                    date={this.state.date}
                ></DailyInput>
            </div>


        );
    }
}

export default PageMain;
