import moment from "moment";
import React from "react";
import isDefined from "../../tools/isDefined";

class TimeInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            datetime: isDefined(props.datetime) ? this.momentToDatetimeLocal(props.datetime) : '',
        };
    }

    componentDidUpdate(prevProps) {
        const timeHasChanged = prevProps.datetime !== this.props.datetime;
        if (timeHasChanged) {

            const stateNeedUpdate = this.props.datetime !== this.state.datetime;
            if (stateNeedUpdate) {
                this.setState({
                    datetime: isDefined(this.props.datetime) ? this.momentToDatetimeLocal(this.props.datetime) : '',
                });
            }
        }
    }

    changeDateTime(newDateTime) {
        this.setState({
            datetime: newDateTime,
        });

        if (isDefined(this.props.onChangeDateTime)) {
            const datetimeMoment = (newDateTime === '') ? undefined : moment(newDateTime);
            this.props.onChangeDateTime(datetimeMoment);
        }
    }

    setTimeToNow() {
        const now = moment();

        const nowFormatted = this.momentToDatetimeLocal(now);

        this.changeDateTime(nowFormatted);
    }

    onChangeInput(newStr) {
        this.changeDateTime(newStr);
    }

    momentToDatetimeLocal(momentDate) {
        return momentDate.format("YYYY-MM-DDTHH:mm");
    }

    render() {
        const testIdPrefix = `time-input--${this.props.testId}--`;
        return (
            <div className="TimeInput">
                <h2 data-test={`${testIdPrefix}title`}>{this.props.title}</h2>
                <input
                    type="datetime-local"
                    value={this.state.datetime}
                    onChange={
                        (event) => {
                            this.onChangeInput(event.target.value)
                        }
                    }
                    data-test={`${testIdPrefix}input`}
                ></input>
                <button
                    onClick={
                        () => {
                            this.setTimeToNow()
                        }
                    }
                    data-test={`${testIdPrefix}now-button`}
                >Maintenant</button>
            </div>
        );
    }
}

export default TimeInput;