const DB_NAME = 'vigie-sommeil';
const STORE_NAME = 'horaires';

export default class IDBHoraires {
    initialize() {
        return new Promise((resolve, reject) => {

            const DBOpenRequest = indexedDB.open(DB_NAME);

            let upgradingDBPromise;

            DBOpenRequest.onerror = (error) => {
                console.error(`Echec de l'ouverture de la base de données '${DB_NAME}' :`, error);
                reject(error);
            };

            DBOpenRequest.onsuccess = () => {
                this.db = DBOpenRequest.result;
                if (upgradingDBPromise === undefined) {
                    resolve();
                } else {
                    upgradingDBPromise.then(resolve).catch(reject)
                }
            };

            DBOpenRequest.onupgradeneeded = () => {
                const db = DBOpenRequest.result;
                upgradingDBPromise = this._buildDB(db);
            };
        });
    }

    _buildDB(db) {
        return new Promise((resolve, reject) => {
            db.onerror = (error) => {
                console.error(`Erreur de construction de la base de donneés '${DB_NAME}' :`, error);
                reject(error);
            };

            db.createObjectStore(STORE_NAME, { keyPath: "date" });

            resolve();
        });
    }

    set(item) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(STORE_NAME, "readwrite");

            transaction.oncomplete = () => {
                resolve();
            };

            transaction.onerror = (error) => {
                console.error(`Erreur de sauvegarde d'un item :`, error);
                reject(error);
            };

            const storeHoraires = transaction.objectStore(STORE_NAME);

            storeHoraires.put(item);
        });
    }

    get(date) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(STORE_NAME, "readonly");

            transaction.oncomplete = () => {
                resolve();
            };

            transaction.onerror = (error) => {
                console.error(`Erreur de lecture d'un item :`, error);
                reject(error);
            };

            const storeHoraires = transaction.objectStore(STORE_NAME);

            const request = storeHoraires.get(date);

            request.onsuccess = () => {
                const record = request.result;
                resolve(record);
            };
        });
    }

    getAll() {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(STORE_NAME, "readonly");

            transaction.oncomplete = () => {
                resolve();
            };

            transaction.onerror = (error) => {
                console.error(`Erreur de lecture des items :`, error);
                reject(error);
            };

            const storeHoraires = transaction.objectStore(STORE_NAME);

            const request = storeHoraires.getAll()

            request.onsuccess = () => {
                const records = request.result;
                resolve(records);
            };
        });
    }
}

export const iDBHoraires = new IDBHoraires();