import React from "react";
import "./DownloadSave.css";
import { dataService } from "../daily-input/data.service";

class DownloadSave extends React.Component {

    save() {
        function download(filename, content) {
            const contentAsJSON = JSON.stringify(content, null, 4);
            const contentURIEncoded = encodeURIComponent(contentAsJSON);

            const downloadElement = document.createElement('a');
            downloadElement.style.display = 'none';

            downloadElement.setAttribute('href', 'data:application/json;charset=utf-8,' + contentURIEncoded);
            downloadElement.setAttribute('download', filename);

            document.body.appendChild(downloadElement);

            downloadElement.click();

            document.body.removeChild(downloadElement);
        }

        const dateFormatted = this.props.date.format('YYYY-MM-DD');
        const filename = `sauvegarde-suivi-sommeil_${dateFormatted}.json`

        dataService.getAllTimes().then((dataHistory) => {
            const payload = {
                records: dataHistory
            };

            download(filename, payload);
        });

    }

    render() {
        return (
            <button
                onClick={
                    () => {
                        this.save()
                    }
                }
                className="save-button"
            >Save</button>
        );
    }
}

export default DownloadSave;